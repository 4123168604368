<template>
  <div class="Awarp">
    <dashCard
      class="photovoltaicBox7"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('photovoltaicBox7_rank','发电量排名', getZEdata)}}</template>
      <template slot="aside">
        <div class="type-group">
          <span
            class="pick-type"
            v-for="(item, idx) in timetType"
            :key="idx"
            @click="changeType(item.code)"
          >
            <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
              item.name
            }}</font>
          </span>
        </div>
        <a-date-picker
          v-if="searchObj.TYPE == '按天'"
          :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-week-picker
          v-if="searchObj.TYPE == '按周'"
          :placeholder="commonLangHandler('powerBox28_selectWeek','选周', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-month-picker
          v-if="searchObj.TYPE == '按月'"
          :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-date-picker
          v-if="searchObj.TYPE == '按年'"
          :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
          mode="year"
          :open="panelOpen"
          @openChange="openChange"
          @panelChange="panelChange"
          format="YYYY"
        />
      </template>
      <div class="card-content1">
        <a-radio-group
          v-model="searchObj.TYPE1"
          button-style="solid"
          @change="handleChange"
        >
          <a-radio-button value="总发电量">{{commonLangHandler('photovoltaicBox11_totalpowerGeneration','总发电量', getZEdata)}}</a-radio-button>
          <a-radio-button value="单位装机发电量">{{commonLangHandler('photovoltaicBox7_powerGeneration','单位装机发电量', getZEdata)}}</a-radio-button>
        </a-radio-group>
      </div>
      <div class="card-content2">
        <chartBoard ref="chart1" :option="chartOption1" />
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <photovoltaicBox7
          :option="option"
          :isModal="true"
          :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
    <script>
export default {
  name: "photovoltaicBox7",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      eqlist: [],
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中

      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
    
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      itemList: [
        {
          code: "光伏逆变器",
          name: "光伏逆变器",
        },
      ],
      panelOpen: false,
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        TYPE1: "总发电量",
        VALUE: this.$moment(), //日期
        ITEM: "", //选中的项目
      },
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      colorList: ["#3366FF"],
    };
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      //console.log("onChange", value, this.searchObj);
      this.getData();
    },
    // 切换项目
    handleChange(value) {
      this.getData();
    },
    //每月报警预警数
    getData() {
      // if(this.searchObj.ITEM == ""){
      //   this.detailInfo = {}
      //   var chart1 = {
      //     categoryData: [],
      //     seriesData: []
      //   }
      //   this.detailInfo.chart1 = chart1
      //   this.initEchart()
      //   return
      // }
      //   let data = {
      //     "deptCode": this.currentSelectDeptInfo.CODE,
      //     "assetNumber":this.searchObj.ITEM,
      //     "check": this.searchObj.TYPE1
      //   }
      let data = {
        deptCode: this.currentSelectDeptInfo.CODE,
        check: this.searchObj.TYPE1,
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      //console.log(data);
      this.detailInfo = {};
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/generation/ranking",
        method: "post",
        data,
      })
        .then((res) => {
      
          let result = res.data.data;
          
          var chart1 = {
            categoryData: result.categoryData,
            seriesData: result.seriesData,
          };
          this.detailInfo.chart1 = chart1;
          this.initEchart();
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    initEchart() {
      var { categoryData, seriesData } = this.detailInfo.chart1;

      var series = [];
      seriesData.forEach((item) => {
        var obj = {
          type: "bar",
          animationDurationUpdate: 2000,
          animationEasingUpdate: "quinticInOut",
          name: item.name,
          data: item.value,
          smooth: false,
          showSymbol: false,
          barGap: 0,
          barWidth: 10,
          emphasis: {
            focus: "series",
          },
        };
        series.push(obj);
      });
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          left: "0%",
          right: "13%",
          bottom: "5%",
          top: "5%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: {
          type: "value",
          name: this.commonLangHandler('photovoltaicBox7_unit','单位(kWh)', this.getZEdata),
          nameLocation: "end", // 在头部
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,

            align: "center",
            verticalAlign: "top",
            padding: [28, 0, 0, 50],
            // padding: [上, 右, 下, 左],
          },
          axisLine: {
            lineStyle: {
              type: "dashed",
              color: "#1f1f1f",
            },
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
            formatter(v) {
              v = v.toString();
              if (v >= 100000000000) {
                return v.substring(0, 5) / 10 + "亿";
              } else if (v >= 10000000000) {
                return v.substring(0, 4) / 10 + "亿";
              } else if (v >= 1000000000) {
                return v.substring(0, 3) / 10 + "亿";
              } else if (v >= 100000000) {
                return v.substring(0, 2) / 10 + "亿";
              } else if (v >= 10000000) {
                return v.substring(0, 4) + "万";
              } else if (v >= 1000000) {
                return v.substring(0, 3) + "万";
              } else if (v >= 100000) {
                return v.substring(0, 2) + "万";
              } else if (v >= 10000) {
                return v.substring(0, 2) / 10 + "万";
              } else if (v >= 1000) {
                return v;
              } else if (v <= -100000000000) {
                return "-" + v.substring(1, 7) / 10 + "亿";
              } else if (v <= -100000000000) {
                return "-" + v.substring(1, 6) / 10 + "亿";
              } else if (v <= -10000000000) {
                return "-" + v.substring(1, 5) / 10 + "亿";
              } else if (v <= -1000000000) {
                return "-" + v.substring(1, 4) / 10 + "亿";
              } else if (v <= -100000000) {
                return "-" + v.substring(1, 3) / 10 + "亿";
              } else if (v <= -10000000) {
                return "-" + v.substring(1, 5) + "万";
              } else if (v <= -1000000) {
                return "-" + v.substring(1, 4) + "万";
              } else if (v <= -100000) {
                return "-" + v.substring(1, 3) + "万";
              } else if (v <= -10000) {
                return "-" + v.substring(1, 3) / 10 + "万";
              } else if (v <= -1000) {
                return v;
              } else {
                return v;
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              width: 0.5,
              color: "#bababa",
            },
          },
          // 控制数据参数最大值和最小值
          // interval: 50,
          // max: 100
        },
        yAxis: {
          type: "category",
          
          data: categoryData,
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: "#696969",
            },
          },
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
            formatter(v) {
              v = v.toString();
              if (v >= 100000000000) {
                return v.substring(0, 5) / 10 + "亿";
              } else if (v >= 10000000000) {
                return v.substring(0, 4) / 10 + "亿";
              } else if (v >= 1000000000) {
                return v.substring(0, 3) / 10 + "亿";
              } else if (v >= 100000000) {
                return v.substring(0, 2) / 10 + "亿";
              } else if (v >= 10000000) {
                return v.substring(0, 4) + "万";
              } else if (v >= 1000000) {
                return v.substring(0, 3) + "万";
              } else if (v >= 100000) {
                return v.substring(0, 2) + "万";
              } else if (v >= 10000) {
                return v.substring(0, 2) / 10 + "万";
              } else if (v >= 1000) {
                return v;
              } else if (v <= -100000000000) {
                return "-" + v.substring(1, 7) / 10 + "亿";
              } else if (v <= -100000000000) {
                return "-" + v.substring(1, 6) / 10 + "亿";
              } else if (v <= -10000000000) {
                return "-" + v.substring(1, 5) / 10 + "亿";
              } else if (v <= -1000000000) {
                return "-" + v.substring(1, 4) / 10 + "亿";
              } else if (v <= -100000000) {
                return "-" + v.substring(1, 3) / 10 + "亿";
              } else if (v <= -10000000) {
                return "-" + v.substring(1, 5) + "万";
              } else if (v <= -1000000) {
                return "-" + v.substring(1, 4) + "万";
              } else if (v <= -100000) {
                return "-" + v.substring(1, 3) + "万";
              } else if (v <= -10000) {
                return "-" + v.substring(1, 3) / 10 + "万";
              } else if (v <= -1000) {
                return v;
              } else {
                return v;
              }
            },
          },
        },
        // 控住柱状图样式
        series: series,
        color: this.colorList,
      };

      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },
  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.photovoltaicBox7 {
  /deep/.ant-radio-group {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background: #f5f5f5;
    .ant-radio-button-wrapper {
      background: inherit;
      margin: 2px 2px;
      border: 0;
      height: 26px;
      line-height: 26px;
      &::before {
        background-color: transparent;
      }
    }
    .ant-radio-button-wrapper-checked {
      background: #ffffff !important;
      border-radius: 2px;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);

      font-size: 14px;
      font-family: ABBvoice_WCNSG_Rg;
      font-weight: 400;
      text-align: center;
      color: #3366ff;
      border: 0;
      &::before {
        background-color: transparent;
      }
    }
  }
  .card-content1 {
    display: flex;
    justify-content: right;
    min-height: 32px;
  }
  .card-content2 {
    flex: 1;
    height: 100%;
    div {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
